import React, { useMemo } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../../GlobalStyles'
import { store } from '../../store'
import { theme } from '../../theme'
import { BreakpointMap, isBrowser, RemScalingOptions } from '@atomixdesign/rem-scaling'
import { InlineFontSetter, RemScalingProvider } from '@atomixdesign/rem-scaling-react'
import { Pipeline, SearchProvider, Variables } from '@sajari/react-hooks'
import { useTheme, StylesProvider, MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

export type AppProps = {
  //
}

export const useThemeBreakpoints = (): BreakpointMap => {
  const theme = useTheme()
  const keys = theme.breakpoints.keys
  const breakpoints = theme.breakpoints.values

  return keys.reduce((carry, key, i) => {
    if (i <= keys.length - 1) {
      // @ts-ignore
      const prevBreakpoint = breakpoints[keys[i - 1]]

      if (prevBreakpoint) {
        carry[key] = [prevBreakpoint, breakpoints[key]]
      }
    }

    return carry
  }, {} as BreakpointMap)
}

export const SAJARI_ENV = process.env.NEXT_PUBLIC_SAJARI_ENV || 'production'

export const App: React.FC<AppProps> = ({ children }) => {
  const themeBreakpoints = useThemeBreakpoints() // This is a helper, it can be replaced with custom code

  const pipeline = useMemo(() => new Pipeline(
    {
      account: process.env.SAJARI_ACCOUNT || '1576802231270850944',
      collection: process.env.SAJARI_COLLECTION || 'creditunion-sa-atomix',
    },
    process.env.SAJARI_PIPELINE || 'default',
  ), [])

  let variables: Variables | undefined = undefined

  if (isBrowser()) {
    const params = new URLSearchParams(window.location.search)
    const q = params.get('q')

    if (q) {
      variables = new Variables({ q })
    }
  }

  const remScaling: RemScalingOptions = {
    min: 12,
    max: theme.typography.fontSize,
    base: theme.typography.fontSize,
    breakpoints: themeBreakpoints,
    fallback: [0, 1600],
    nonce: process.env.nonce,
  }

  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <RemScalingProvider options={remScaling}>
              <SearchProvider search={{ pipeline, variables }} defaultFilter={`environment="${SAJARI_ENV}"`}>
                <InlineFontSetter />
                <CssBaseline />
                <GlobalStyles />
                {children}
              </SearchProvider>
            </RemScalingProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </Provider>
    </StylesProvider>
  )
}
